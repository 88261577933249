
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
} from "vue";
import { gpf } from "@/utils/global-functions";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const servicesV1Public = async () => await import(`@/services/v1/public/index`);

export default defineComponent({
  name: "ModalUpdateProfile",
  components: {
    ModalTemplate,
  },
  setup() {
    // START --- action form for ubah password ------------------------------------------------
    const form = reactive({
      oldPassword: "",
      newPassword: "",
    });

    const emptyForm = () => {
      form.oldPassword = "";
      form.newPassword = "";
    };

    onMounted(() => {
      emptyForm();
    });

    const requestUbahPassword = async () => {
      const { resetPassword } = await servicesV1Public();

      gpf.gLoading.show();

      try {
        const payload = new URLSearchParams();
        payload.append("oldPassword", form.oldPassword);
        payload.append("newPassword", form.newPassword);

        await resetPassword(payload);
        await gpf.gLoading.hide();

        emptyForm();
        gpf.handleSdsV2("Ubah Password Berhasil", "toast");
        gpf.hideModal("#modal-ubah-password");
      } catch (e: any) {
        const err = e.response;
        console.log("err response ", err);

        gpf.gLoading.hide();
        gpf.handleEds(e);
      }
    };

    const visible = reactive({
      oldPassword: false,
      newPassword: false,
    });

    const onCancelClick = () => {
      emptyForm();
      visible.oldPassword = false;
      visible.newPassword = false;
      gpf.hideModal("#modal-ubah-password");
    };
    // END --- action form for ubah password --------------------------------------------------

    return {
      gpf,
      form,
      requestUbahPassword,
      visible,
      onCancelClick,
    };
  },
});
